import React from 'react'

import BlogPage from '../components/layouts/blog_page'
import TextLink from '../components/common/text_link'
import Em from '../components/common/emphasis'
import P from '../components/common/paragraph'

import META_IMAGE_SRC from '../images/hand_in_field.jpg'

export default ({ location }) => (
  <BlogPage
    location={location}
    metaImage={META_IMAGE_SRC}
    metaImageAlt="A hand up in the air in a crop field"
  >
    <P>I made a little thing!</P>

    <P>
      The good people over at{' '}
      <TextLink href="https://caniuse.com/" text="caniuse.com" /> provide a
      fantastic service allowing you to look up global browser support levels
      for any web feature you might want to use. Inspired by the fact that
      "Flexbox" is by far their most-searched-for feature, I thought I'd make a
      little shortcut to the web dev community's most burning question:{' '}
      <Em>Can I Use Flexbox?</Em>
    </P>

    <P>
      Spoiler: the answer is <Em>yes</Em>.
    </P>

    <P>
      You can find the app at{' '}
      <TextLink
        href="https://unwitting.github.io/can-i-use-flexbox/"
        text="unwitting.github.io/can-i-use-flexbox"
      />
      . It's fast, it's updated with fresh data from caniuse every day, and
      it'll always give you the answer you want.
    </P>

    <P>Enjoy!</P>
  </BlogPage>
)
